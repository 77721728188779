import { DestroyRef, inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EUserLookAndFeelId, EAuctionRoomLookAndFeelId } from '@caronsale/cos-models';
import { CosGeneralUserClientService } from '@cosCoreServices/cos-general-user-client/cos-general-user-client.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { BehaviorSubject, distinctUntilChanged, filter, map, switchMap } from 'rxjs';

export enum EPartnerName {
  MERCEDES_BENZ = 'Mercedes-Benz',
  CARONSALE = 'CarOnSale',
}

export interface LookAndFeel {
  partnerName?: EPartnerName;
  partnerLogo?: string;
  headerLogo?: string;
  banner?: string;
  bannerGradient?: string;
  searchFilterPredefinedMake?: string;
}

const LNF_REPO: Record<EUserLookAndFeelId, LookAndFeel> = {
  [EUserLookAndFeelId.DEFAULT]: {
    partnerName: EPartnerName.CARONSALE,
    bannerGradient: 'linear-gradient(98deg, #B3B3B3 0%, #404040 100%)',
  },
  [EUserLookAndFeelId.MERCEDES_BENZ]: {
    partnerName: EPartnerName.MERCEDES_BENZ,
    bannerGradient:
      'radial-gradient(87% 109% at 50% 52%, #00FFFF00 0%, #000000FF 100%),radial-gradient(57% 87% at 85% 66%, #98F9FAFF 0%, #00000000 100%),radial-gradient(250% 133% at 93% 19%, #FFD2FBDE 0%, #00000000 100%),linear-gradient(0deg, #000000FF 0%, #000000FF 100%)',
    searchFilterPredefinedMake: 'Mercedes-Benz',
    partnerLogo: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1724926136/caronsale/look-and-feel/mercedes/lhvgmzkab4htj9m4ljsh.svg',
  },
};

const AUCTION_ROOM_LNF_REPO: Record<EAuctionRoomLookAndFeelId, LookAndFeel> = {
  [EAuctionRoomLookAndFeelId.DEFAULT]: {
    ...LNF_REPO.DEFAULT,
    banner: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1724926002/caronsale/look-and-feel/default/qasdg1lpo5ax2sroqrse.svg',
  },
  [EAuctionRoomLookAndFeelId.MERCEDES_BENZ]: {
    ...LNF_REPO.MERCEDES_BENZ,
    banner: 'https://res.cloudinary.com/castle-tech-gmbh/image/upload/v1724926090/caronsale/look-and-feel/mercedes/tfjbzz8fodmxhcchfvem.svg',
  },
};

@Injectable()
export class LookAndFeelService implements OnDestroy {
  // dependencies
  private generalUserClient = inject(CosGeneralUserClientService);
  private analyticsService = inject(ProductAnalyticsService);
  private destroyRef = inject(DestroyRef);

  private selectedLNFSubject = new BehaviorSubject<LookAndFeel>(null);
  public selectedLookAndFeel$ = this.selectedLNFSubject.pipe(filter(Boolean));

  public init() {
    this.analyticsService
      .isOn('mpw-look-and-feel')
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.generalUserClient.generalUser$.pipe(
            map(u => (u?.account?.lookAndFeelIds ?? []).find(lnf => lnf !== EUserLookAndFeelId.DEFAULT && this.getUserLookAndFeelById(lnf))),
            distinctUntilChanged(),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(lookAndFeel => {
        this.setLookAndFeelAsActive(lookAndFeel ?? EUserLookAndFeelId.DEFAULT);
      });
  }
  public ngOnDestroy(): void {
    this.selectedLNFSubject.complete();
  }

  public getLookAndFeelForAuctionRoom(id: EAuctionRoomLookAndFeelId): LookAndFeel {
    return AUCTION_ROOM_LNF_REPO[id] || AUCTION_ROOM_LNF_REPO[EAuctionRoomLookAndFeelId.DEFAULT];
  }

  public getUserLookAndFeelById(id: EUserLookAndFeelId): LookAndFeel | null {
    return LNF_REPO[id];
  }

  public getAuctionRoomLookAndFeelById(id: EAuctionRoomLookAndFeelId): LookAndFeel | null {
    return AUCTION_ROOM_LNF_REPO[id];
  }

  private setLookAndFeelAsActive(lookAndFeelId: EUserLookAndFeelId): void {
    let selectedLNF = LNF_REPO[lookAndFeelId];
    selectedLNF = selectedLNF || LNF_REPO[EUserLookAndFeelId.DEFAULT];

    this.selectedLNFSubject.next(selectedLNF);
  }

  public resetLookAndFeel() {
    this.selectedLNFSubject.next(null);
  }
}
