import { Inject, Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CosAuthService } from '@cosCoreServices/cos-auth/cos-auth.service';
import { EUserType, Validation } from '@caronsale/cos-models';
import { TBuyerCockpitCardType } from '../../buyer/cockpit/partials/cockpit-activity-cards/cockpit-activity-cards-config';
import { EBuyerCockpitAuctionListType } from '../../buyer/cockpit/cockpit.component';
import { EBuyerAuctionTabId } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';

// used for the userID GTM - TAG if a user is not registered
const UNREGISTERED_USER_UUID = 'unregistered';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public constructor(
    private gtmService: GoogleTagManagerService,
    private cosAuthService: CosAuthService,
    @Inject('googleTagManagerId') private googleTagManagerId: string,
    @Inject('googleAnalyticsId') private googleAnalyticsId: string,
  ) {
    // Monkey Patch the GTM Service to prevent outdated event format from breaking everything
    this.gtmService.addGtmToDom = function addGtmToDom() {
      return new Promise((resolve, reject) => {
        if ((this as any).isLoaded) {
          return resolve((this as any).isLoaded);
        }

        const doc = (this as any).browserGlobals.documentRef();
        const gtmScript = doc.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;

        gtmScript.src = (this as any).applyGtmQueryParams(
          (this as any).config.gtm_resource_path ? (this as any).config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
        );
        gtmScript.addEventListener('load', () => {
          return resolve(((this as any).isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
        if (this.googleTagManagerCSPNonce) {
          gtmScript.setAttribute('nonce', this.googleTagManagerCSPNonce);
        }
        doc.head.insertBefore(gtmScript, doc.head.firstChild);
      });
    };

    // Add correct Config setup to the GTM Service
    this.gtag('js', new Date());
    this.gtag('config', this.googleAnalyticsId);
  }

  private gtag(..._args) {
    this.gtmService.pushTag(arguments); // eslint-disable-line prefer-rest-params
  }

  public static translateLegacyUserType(userType: EUserType): string {
    switch (userType) {
      case EUserType.DEALERSHIP:
        return 'seller';
      case EUserType.DEALERSHIP_SUPERVISOR:
        return 'seller_supervisor';
      case EUserType.INTERNAL:
        return 'internal';
      case EUserType.SALESMAN:
        return 'buyer';
      case EUserType.TRANSPORTATION_PROVIDER:
        return 'transportation_provider';
      default:
        return null;
    }
  }

  public pageEmitter(eventName: string, pageName: string): void {
    if (Validation.isUndefinedNullOrEmptyString(this.googleTagManagerId)) {
      // prevent exceptions in the embedded app
      return;
    }

    this.gtag('event', eventName, {
      event: eventName,
      pageName: pageName,
      userID: Validation.isUndefinedOrNull(this.cosAuthService.getCurrentAuthentication())
        ? UNREGISTERED_USER_UUID
        : this.cosAuthService.getCurrentAuthentication().internalUserUUID,
    });
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: string | number | string[] | number[] | null = null,
    trackingUUID: string | null = null,
    search: string | null = null,
  ): void {
    try {
      if (Validation.isUndefinedNullOrEmptyString(this.googleTagManagerId)) {
        // prevent exceptions in the embedded app
        return;
      }

      const currentUser = this.cosAuthService.getCurrentAuthentication();

      const user = {
        user_uuid: Validation.isUndefinedOrNull(currentUser) ? UNREGISTERED_USER_UUID : currentUser.internalUserUUID,
        user_type: Validation.isUndefinedOrNull(currentUser) ? null : GoogleAnalyticsService.translateLegacyUserType(currentUser.type),
        user_legacyType: Validation.isUndefinedOrNull(currentUser) ? null : EUserType[currentUser.type],
      };

      // eslint-disable-next-line prefer-const
      let eventData: Record<string, any> = {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        // TODO: Will be deprecated in the future. To be removed. 'user.uuid' object will be used instead.
        userID: user.user_uuid,
        ...user,
      };

      if (eventValue) {
        eventData.eventValue = eventValue;
      }

      if (trackingUUID) {
        eventData.auctionUUID = trackingUUID;
      }

      if (search) {
        eventData.searchRequest = search;
      }

      this.gtag('event', eventName, eventData);
    } catch (error) {
      console.error('Error while tracking event', error);
    }
  }

  public trackPreviousAuctionClick(auctionID: string): void {
    this.eventEmitter('auction_detail-click-previous', 'auction_detail', 'click', 'previous', auctionID, auctionID);
  }

  public trackNextAuctionClick(auctionID: string): void {
    this.eventEmitter('auction_detail-click-next', 'auction_detail', 'click', 'next', auctionID, auctionID);
  }

  public trackCloseActionDetailModal(auctionID: string): void {
    this.eventEmitter('auction_detail-close-modal', 'auction_detail', 'close', 'modal', auctionID, auctionID);
  }

  public trackBuyerBannerClick(bannerLink: string): void {
    this.eventEmitter('buyer-click-banner', 'buyer', 'click', 'banner', bannerLink);
  }

  public trackOpenAuctionPreview(): void {
    this.eventEmitter('login-form-AuctionPreviewed', 'user', 'click', 'login-form-Preview auction');
  }

  public trackMenuNavigationClick(menuItem: string): void {
    this.eventEmitter('menu-navigation', 'user', 'click', 'menu-navigation', menuItem);
  }

  public trackFilterChange(name: string, value: any): void {
    this.eventEmitter(name, 'Buyer', 'Filter', 'filter-AuctionId', value);
  }

  public trackFilterVisualChange(trackingName: string, isCollapsed: boolean): void {
    this.eventEmitter(`${trackingName}-filter`, 'Buyer', 'Filter', `${trackingName}-filter-${isCollapsed ? 'expanded' : 'collapsed'}`);
  }

  public trackPriceFilterChange(name: string, value: any): void {
    this.eventEmitter(name, 'Buyer', 'Filter', undefined, value);
  }

  public trackLocationFilterChange(name: string, label: string, value: any): void {
    this.eventEmitter(name, 'Buyer', 'Filter', label, value);
  }

  public trackRemoveAllFilters(): void {
    this.eventEmitter('RemoveAuctionFilter', 'Buyer', 'Filter', 'remove-auction-filters', 'Delete all button');
  }

  public trackRemoveFilter(value: string): void {
    this.eventEmitter('RemoveAuctionFilter', 'Buyer', 'Filter', 'remove-auction-filter', value);
  }

  public trackOpenReportDetails(value: string): void {
    this.eventEmitter('auction_detail-open-report', 'auction_detail', 'open', 'report', value);
  }

  public trackAudioPlay(): void {
    this.eventEmitter('auction_detail-play-audio', 'auction_detail', 'play');
  }

  /**
   * Creates and emits an event with the given parameters.
   * Generates the eventName by concatenating the given parameters.
   * For example: eventCategory = 'activity-card', eventAction = 'click', eventLabel = 'show-all-auctions'
   * will generate the eventName: 'activity-card_click_how-all-auctions'
   */
  public createAndEmitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue?: string | number | string[] | number[] | null,
  ): void {
    const eventName = `${eventCategory}_${eventAction}_${eventLabel}`;
    this.eventEmitter(eventName, eventCategory, eventAction, eventLabel, eventValue);
  }

  public trackBuyerCockpitActivityCardClick(cardType: TBuyerCockpitCardType): void {
    switch (cardType) {
      case 'blocked':
        this.createAndEmitEvent('activity-card', 'click', 'blocked');
        break;
      case 'checkAuctions':
        this.createAndEmitEvent('activity-card', 'click', 'show-all-auctions');
        break;
      case 'openCheckouts':
        this.createAndEmitEvent('activity-card', 'click', 'checkout');
        break;
      case 'openPayments':
        this.createAndEmitEvent('activity-card', 'click', 'make-payment');
        break;
      case 'openPickups':
        this.createAndEmitEvent('activity-card', 'click', 'pickup');
        break;
      case 'openRenegotiations':
        this.createAndEmitEvent('activity-card', 'click', 'renegotiation');
        break;
      case 'outbid':
        this.createAndEmitEvent('activity-card', 'click', 'outbid');
        break;
      case 'setAuctionPreferences':
        this.createAndEmitEvent('activity-card', 'click', 'set-auction-preferences');
        break;
      case 'uploadDocuments':
        this.createAndEmitEvent('activity-card', 'click', 'complete-registration');
        break;
      case 'buyerLimitPurchaseBlocked':
        this.createAndEmitEvent('activity-card', 'click', 'limit-purchase-blocked-make-payment');
        break;
      case 'buyerLimitPurchaseWarning':
        this.createAndEmitEvent('activity-card', 'click', 'limit-purchase-warning-make-payment');
        break;
    }
  }

  public trackBuyerCockpitQuickSearchFiltersApplied({
    distanceTo,
    makes,
    mileageTo,
    priceTo,
    registrationFrom,
    model,
  }: {
    makes: string[];
    mileageTo: number;
    priceTo: number;
    registrationFrom: number;
    distanceTo: number;
    model: string;
  }) {
    if (makes && makes.length) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'make', makes);
    }

    if (mileageTo) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'mileage-to', mileageTo);
    }

    if (priceTo) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'price-to', priceTo);
    }

    if (registrationFrom) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'registration-from', registrationFrom);
    }

    if (distanceTo) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'radius-to', distanceTo);
    }

    if (model) {
      this.createAndEmitEvent('cockpit-search', 'filter', 'model', model);
    }
  }

  public trackBuyerCockpitQuickSearchAllAuctionsClick() {
    this.createAndEmitEvent('cockpit-search', 'click', 'all-auctions');
  }

  public trackBuyerCockpitQuickSearchGoToFilteredAuctionsClick({
    distanceTo,
    makes,
    mileageTo,
    priceTo,
    registrationFrom,
    model,
  }: {
    makes: string[];
    mileageTo: number;
    priceTo: number;
    registrationFrom: number;
    distanceTo: number;
    model: string;
  }) {
    const anyFilterApplied = Boolean(makes?.length || mileageTo || priceTo || registrationFrom || distanceTo || model);

    if (anyFilterApplied) {
      this.createAndEmitEvent('cockpit-search', 'click', 'search-auctions-with-filter');
    } else {
      this.createAndEmitEvent('cockpit-search', 'click', 'search-auctions-without-filter');
    }
  }

  public trackBuyerCockpitCardClick(cardType: EBuyerCockpitAuctionListType): void {
    const eventLabel = cardType === EBuyerCockpitAuctionListType.Running ? 'auction-latest' : `auction-${cardType}`;
    this.createAndEmitEvent('cockpit', 'open', eventLabel);
  }

  public trackBuyerCockpitSectionGoToAuctionsClick(tabId: EBuyerAuctionTabId, auctionListType?: EBuyerCockpitAuctionListType) {
    if (auctionListType) {
      return this.createAndEmitEvent('cockpit', 'click', `auction-list-${auctionListType}`);
    }

    switch (tabId) {
      case EBuyerAuctionTabId.RECOMMENDED_AUCTIONS:
        this.createAndEmitEvent('cockpit', 'click', 'auction-list-recommended');
        break;
      case EBuyerAuctionTabId.WATCHLIST:
        this.createAndEmitEvent('cockpit', 'click', 'auction-list-watchlist');
        break;
      case EBuyerAuctionTabId.RUNNING_AUCTIONS:
        this.createAndEmitEvent('cockpit', 'click', 'auction-list-latest');
        break;
    }
  }

  public trackBuyerCockpitAllAuctionsMenuItemClick() {
    this.createAndEmitEvent('cockpit', 'click', 'all-auctions-from-menu');
  }

  public trackBidByIncrementFromListViewClick() {
    this.createAndEmitEvent('auction-list', 'click', 'bid-by-increment');
  }

  public trackBidByValueFromListViewClick() {
    this.createAndEmitEvent('auction-list', 'click', 'bid-by-value');
  }

  public trackBidByIncrementFromDetailViewClick() {
    this.createAndEmitEvent('auction-detail', 'click', 'bid-by-increment');
  }

  public trackBidByValueFromDetailViewClick() {
    this.createAndEmitEvent('auction-detail', 'click', 'bid-by-value');
  }

  public trackBiddingAgentFromDetailViewClick() {
    this.createAndEmitEvent('auction-detail', 'click', 'bidding-agent');
  }

  public trackRemoveBiddingAgentFromDetailViewClick() {
    this.createAndEmitEvent('auction-detail', 'click', 'cancel-bidding-agent');
  }

  public trackBidByIncrementFromDetailViewFooterClick() {
    this.createAndEmitEvent('auction-detail-footer', 'click', 'bid-by-increment');
  }

  public trackBidByValueFromDetailViewFooterClick() {
    this.createAndEmitEvent('auction-detail-footer', 'click', 'bid-by-value');
  }

  public trackBiddingAgentFromDetailViewFooterClick() {
    this.createAndEmitEvent('auction-detail-footer', 'click', 'bidding-agent');
  }

  public trackRemoveBiddingAgentFromDetailViewFooterClick() {
    this.createAndEmitEvent('auction-detail-footer', 'click', 'cancel-bidding-agent');
  }

  public trackMenuPriceListClick() {
    this.createAndEmitEvent('menu', 'click', 'price-list');
  }

  public trackBannerCompleteRegistrationClick() {
    this.createAndEmitEvent('banner', 'click', 'complete-registration');
  }

  public trackRenegotiationBuyerCounterofferSubmitClick() {
    this.createAndEmitEvent('renegotiation-buyer', 'click', 'submit-counteroffer');
  }

  public trackRenegotiationBuyerWithdrawClick() {
    this.createAndEmitEvent('renegotiation-buyer', 'click', 'withdraw');
  }

  public trackRenegotiationBuyerAcceptOfferClick() {
    this.createAndEmitEvent('renegotiation-buyer', 'click', 'purchase-last-ask');
  }

  public trackRenegotiationBuyerCounterOfferSetInput() {
    this.createAndEmitEvent('renegotiation-buyer', 'input', 'counteroffer-set');
  }
}
